import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';

import { type EnumsBlockSearchSortBy } from '@lp-lib/api-service-client/public';
import { type BlockType } from '@lp-lib/game';

import { DefaultBlockList } from '../components/RoutedBlock';
import { AdminView } from '../pages/Admin/AdminView';
import { booleanify } from '../utils/common';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const q = url.searchParams.get('q') ?? '';
  const type = url.searchParams.get('type');
  const brandId = url.searchParams.get('brand-id');
  const sortBy = url.searchParams.get('sort-by');
  const all = booleanify(url.searchParams.get('all'));
  return { q, type, brandId, sortBy, all };
}

function BlockSearch() {
  const { q, type, brandId, sortBy, all } =
    useLoaderData<typeof clientLoader>();
  return (
    <AdminView
      search={{
        targetPathname: '/admin/blocks/search',
        placeholder: 'Search Blocks',
      }}
      className='bg-library-2023-07'
    >
      <div className='w-full h-full pt-2.5 px-10'>
        <DefaultBlockList
          q={q}
          type={type as BlockType | null}
          brandId={brandId}
          sortBy={sortBy as EnumsBlockSearchSortBy | null}
          all={all}
        />
      </div>
    </AdminView>
  );
}

export const Component = BlockSearch;
